import { IReportElement } from "@monorepo/reports/src/views/InventoriesRegisterReportView/types/reportElement";
import { fields } from "@monorepo/utils/src/variables/projectsData/inventoriesRegisterReport/fields";

type Keys = keyof typeof fields;
type Values = typeof fields[Keys];

export const convertApiItemToUi = (item: IReportElement): Record<Values, string> => ({
  [fields.TABLE_NUMBER]: item.index || "",
  [fields.TABLE_FUND_NUMBER]: item.fundNumber || "",
  [fields.TABLE_INVENTORY_NUMBER]: item.inventoryNumber || "",
  [fields.TABLE_INVENTORY_YEAR]: item.inventoryYear || "",
  [fields.TABLE_INVENTORY_TITLE]: item.inventoryHeader || "",
  [fields.TABLE_RECEIVED_TOTAL_EAD]: item.eadCountTotal || "",
  [fields.TABLE_RECEIVED_EAD]: item.eadCountReceived || "",
});
