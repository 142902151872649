export const breadcrumbs = [
  {
    text: "Главная",
    disabled: false,
    href: "/",
  },
  {
    text: "Учетные формы: Реестр описей дел, документов",
    disabled: true,
    href: "breadcrumbs_link_1",
  },
];
