import { v4 as uuid } from "uuid";
// import { headerCheckboxObject } from "@monorepo/utils/src/variables/headerCheckboxValue";
import { fields } from "./fields";

export const tableHeaders = [
  // headerCheckboxObject(),
  {
    text: "№ п/п",
    tooltip: "Номер по порядку",
    defaultWidth: 90,
    value: fields.TABLE_NUMBER,
    isSorted: true,
    isHandle: false,
    id: uuid(),
  },
  {
    text: "№ фонда",
    tooltip: "Номер фонда",
    defaultWidth: 150,
    value: fields.TABLE_FUND_NUMBER,
    isSorted: true,
    isHandle: false,
    id: uuid(),
  },
  {
    text: "№ описи дел",
    tooltip: "Номер описи дел",
    defaultWidth: 150,
    value: fields.TABLE_INVENTORY_NUMBER,
    isSorted: true,
    isHandle: false,
    id: uuid(),
  },
  {
    text: "Год описи дел",
    tooltip: "Год описи дел",
    defaultWidth: 120,
    value: fields.TABLE_INVENTORY_YEAR,
    isSorted: true,
    isHandle: false,
    id: uuid(),
  },
  {
    text: "Заголовок описи дел",
    tooltip: "Заголовок описи дел",
    defaultWidth: 240,
    value: fields.TABLE_INVENTORY_TITLE,
    isSorted: true,
    isHandle: false,
    id: uuid(),
  },
  {
    text: "Поступило ЭАД, всего/Ожидаем",
    tooltip: "Количество ЭАД, поступивших на хранение",
    defaultWidth: 150,
    value: fields.TABLE_RECEIVED_TOTAL_EAD,
    isSorted: true,
    isHandle: false,
    id: uuid(),
  },
  {
    text: "Поступило ЭАД, по л.с./Ожидаем",
    tooltip: "Их них ЭАД по личному составу",
    defaultWidth: 150,
    value: fields.TABLE_RECEIVED_EAD,
    isSorted: true,
    isHandle: false,
    id: uuid(),
  },
];
