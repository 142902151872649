export interface IInventoriesRegisterReport {
  FILTER_FUND: string;

  TABLE_NUMBER: string;
  TABLE_FUND_NUMBER: string;
  TABLE_INVENTORY_NUMBER: string;
  TABLE_INVENTORY_YEAR: string;
  TABLE_INVENTORY_TITLE: string;
  TABLE_RECEIVED_TOTAL_EAD: string;
  TABLE_RECEIVED_EAD: string;
}

export const fields: IInventoriesRegisterReport = {
  FILTER_FUND: "fundCode",

  TABLE_NUMBER: "number",
  TABLE_FUND_NUMBER: "fundNumber",
  TABLE_INVENTORY_NUMBER: "inventoryNumber",
  TABLE_INVENTORY_YEAR: "inventoryYear",
  TABLE_INVENTORY_TITLE: "inventoryHeader",
  TABLE_RECEIVED_TOTAL_EAD: "eadCountTotal",
  TABLE_RECEIVED_EAD: "eadCountReceived",
};
