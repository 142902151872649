import { fields } from "./fields";
import { educationIds } from "@monorepo/utils/src/variables/educationIds";

export const filtersElements = [
  {
    value: fields.FILTER_FUND,
    title: "Фонд",
    tooltip: "Выбор из списка с предиктивным вводом",
    type: "autocomplete",
    showSelectedKey: "code",
    autocompleteActionName: "getAutocompleteFilterByCode",
    width: 250,
    educationId: educationIds.listViewSettings,
    className: "mt-4 fund-filter",
  },
];
